body { background-color: #ffffff; }
body { color: var(--clr-2815); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 30px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 33px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 12px;

}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
summary {
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-2816);}
a:hover {color: var(--clr-2817);}
/* Main Menu:2 */
nav.me-Menu.MES2 {
& .menu-item.MEC2, & .menu-item.MEC2 > div.MEC2{ & > a.MEC2{color: var(--clr-2818);
font-size: 16px;
@media #{$large-up} {
font-size: 24px;
}text-transform: uppercase;
}
 &:hover > a.MEC2{color: var(--clr-2819);
}
 }
&.horizontal > .menu-item.MEC2 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC2 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC2 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC2 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC2.active { & > a{ color: var(--clr-2819);}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Home Carousel:3 */
.MES3 {
& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: 18;
border-radius: 50%;
border-style:solid;
border-width:6px;
@media #{$medium-up} {
border-width: 6px;
};
@media #{$large-up} {
border-width: 6px;};
&:hover{border-color: 19;}
background-color: 1;
background-clip: padding-box;
&:hover {background-color: 25;}
width:6px;
height:6px;
@media #{$medium-up} {
width:6px;
height:6px;
};
@media #{$large-up} {
width:6px;
height:6px;
};
&:hover{background-color: 25;}
}}
 }
/* Copyright Panel:4 */
.MES4 {
font-size: 12.8px;
 }
.MES4 {
font-size: 12.8px;
 }
cite.MEC4{
font-size: 12.8px;
}
/* Responsive Menu:5 */
nav.responsive-menu {
.menu-item.MEC5{background-color: var(--clr-2818);}
& .menu-item.MEC5, & .menu-item.MEC5 > div.menu-item-wrap{ & > a.MEC5, & > i{color: var(--clr-2820);
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC5 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 15px;}

& .sub-menu{ .sub-menu {}}

 }
/* Horizontal Main Menu:6 */
nav.me-Menu.MES6 {
& .menu-item.MEC6, & .menu-item.MEC6 > div.MEC6{ & > a.MEC6{color: var(--clr-2818);
text-transform: uppercase;
}
 &:hover > a.MEC6{color: var(--clr-2819);
}
 }
&.horizontal > .menu-item.MEC6 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC6 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC6 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC6 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

@media #{$large-up} {
& > .menu-item > a{padding: 30px 20px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Body Style:7 */
.MES7 {
background-color: var(--clr-2815);
&:hover, &.hover { background-color: var(--clr-2815);}
color: #ffffff;
 }
.MES7 {
background-color: var(--clr-2815);
&:hover, &.hover { background-color: var(--clr-2815);}
color: #ffffff;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: #ffffff;
 }
 }
cite.MEC7{
color: #ffffff;
}
/* Banner Panel:8 */
.MES8 {
background-color: var(--clr-2821);
color: #ffffff;
padding: 15px;

 }
.MES8 {
background-color: var(--clr-2821);
color: #ffffff;
padding: 15px;

h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: #ffffff;
 }
 }
cite.MEC8{
color: #ffffff;
}
/* About List :9 */
.MES9 {
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
 }
.MES9 {
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
 }
cite.MEC9{
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* Horizontal Main Menu2:10 */
nav.me-Menu.MES10 {
& .menu-item.MEC10, & .menu-item.MEC10 > div.MEC10{ & > a.MEC10{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC10{color: var(--clr-2819);
}
 }
&.horizontal > .menu-item.MEC10 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC10 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC10 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC10 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

@media #{$large-up} {
& > .menu-item > a{padding: 30px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Main Menu white:11 */
nav.me-Menu.MES11 {
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: #ffffff;
font-size: 16px;
@media #{$large-up} {
font-size: 24px;
}text-transform: uppercase;
}
 &:hover > a.MEC11{color: var(--clr-2819);
}
 }
&.horizontal > .menu-item.MEC11 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC11 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC11 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC11 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC11.active { & > a{ color: var(--clr-2819);}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Primary:12 */
.MES12 {
background-color: var(--clr-2815);
color: #ffffff;
 }
/* Primary:13 */
.MES13 {
background-color: var(--clr-2815);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-2815);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Header Scroll Panel:14 */
.MES14 {
background-color: var(--clr-2822);
 }
.MES14 {
background-color: var(--clr-2822);
 }
/* News List:15 */
.MES15 {
background-color: var(--clr-2820);
color: var(--clr-2815);
 }
.MES15 {
background-color: var(--clr-2820);
color: var(--clr-2815);
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: var(--clr-2815);
 }
 }
cite.MEC15{
color: var(--clr-2815);
}
/* Form Submit Button:16 */
.MES16 {
background-color: var(--clr-2820);
&:hover {background-color: var(--clr-2823);}
color: var(--clr-2815);
&:hover { color: var(--clr-2815);}
border-radius: 20px;
padding: 5px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
&:hover { border-color: transparent; }
 }
/* Share Buttons:18 */
.me-block.me-ShareButton .MES18{
figure.MEC18 { background-color: #ffffff;
&:hover{background-color: #ffffff;}
 }
figure.MEC18 { border-color: var(--clr-2815);
border-style: solid;
&:hover{border-color: var(--clr-2815);}
border-width: 1px;
 }
figure.MEC18{ a { font-size: 100px;

color: var(--clr-2815);
 }
&:hover a {color: var(--clr-2815);}
 }

 }
/* News Grid:19 */
.MES19 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-2820);}
padding: 15px;

@media #{$large-up} {
padding: 50px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-2815);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES19 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-2820);}
padding: 15px;

@media #{$large-up} {
padding: 50px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-2815);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Buy Button:20 */
.MES20 {
background-color: var(--clr-2816);
&:hover {background-color: var(--clr-2824);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 20px 0;
padding: 15px 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Alternate:21 */
.MES21 {
background-color: var(--clr-2816);
color: #ffffff;
 }
/* Alternate:22 */
.MES22 {
background-color: var(--clr-2816);
color: #ffffff;
 }
.MES22 {
background-color: var(--clr-2816);
color: #ffffff;
h1.MEC22, h2.MEC22, h3.MEC22, h4.MEC22, h5.MEC22, h6.MEC22 { color: #ffffff;
 }
 }
cite.MEC22{
color: #ffffff;
}
/* Shade 1:23 */
.MES23 {
background-color: var(--clr-2825);
 }
/* Shade 1:24 */
.MES24 {
background-color: var(--clr-2825);
color: var(--clr-2815);
 }
/* Shade 1:25 */
.MES25 {
background-color: var(--clr-2825);
 }
.MES25 {
background-color: var(--clr-2825);
 }
/* Shade 1:26 */
.MES26 {
background-color: var(--clr-2825);
color: var(--clr-2815);
 }
.MES26 {
background-color: var(--clr-2825);
color: var(--clr-2815);
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-2815);
 }
 }
cite.MEC26{
color: var(--clr-2815);
}
/* Shade 2:27 */
.MES27 {
background-color: var(--clr-2820);
color: var(--clr-2815);
 }
/* Shade 2:28 */
.MES28 {
background-color: var(--clr-2820);
color: var(--clr-2815);
 }
.MES28 {
background-color: var(--clr-2820);
color: var(--clr-2815);
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: var(--clr-2815);
 }
 }
cite.MEC28{
color: var(--clr-2815);
}
/* Buy Button:29 */
.MES29 {
background-color: var(--clr-2826);
&:hover {background-color: var(--clr-2827);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 20px 0;
padding: 15px 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Continue Shopping:30 */
.MES30 {
background-color: var(--clr-2820);
&:hover {background-color: var(--clr-2825);}
color: var(--clr-2815);
border-radius: 20px 0;
padding: 15px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Dark:31 */
.MES31 {
background-color: var(--clr-2826);
color: #ffffff;
 }
/* Dark:32 */
.MES32 {
background-color: var(--clr-2826);
color: #ffffff;
 }
.MES32 {
background-color: var(--clr-2826);
color: #ffffff;
h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: #ffffff;
 }
 }
cite.MEC32{
color: #ffffff;
}
/* Logo style:33 */
.MES33 {
color: var(--clr-2815);
 }
.MES33 {
color: var(--clr-2815);
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: var(--clr-2815);
 }
&:hover { h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: var(--clr-2819);} }
 }
cite.MEC33{
color: var(--clr-2815);
}
/* Black Button:35 */
.MES35 {
background-color: var(--clr-2815);
&:hover {background-color: var(--clr-2818);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 20px 0;
padding: 15px 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Artprint frame:37 */
.MES37 {
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-2825);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES37 {
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-2825);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Seperator:38 */
.MES38 {
padding: 5px 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-2820);}
& > hr {border-top-style: solid;}
 }
